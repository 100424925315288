import { AppProps } from 'next/app'
import { ChakraProvider } from '@chakra-ui/react'
import { AuthProvider } from 'lib/firebase/web/auth/provider'
import { TRPCProvider } from 'lib/trpc/provider'

export default function App({ Component, pageProps }: AppProps) {
  // const apolloClient = useApollo(pageProps)
  return (
    <AuthProvider>
      <TRPCProvider>
        <ChakraProvider>
          <Component {...pageProps} />
        </ChakraProvider>
      </TRPCProvider>
    </AuthProvider>
  )
}
