import { QueryClient, QueryClientProvider } from 'react-query'
import superjson from 'superjson'
import React, { useMemo, useState } from 'react'
import { trpc } from '.'
import { useAuth } from 'lib/firebase/web/auth/provider'

export function TRPCProvider({ children }: { children: React.ReactNode }) {
  const authState = useAuth()
  const [queryClient] = useState(() => new QueryClient())
  const trpcClient = useMemo(
    () =>
      trpc.createClient({
        url: '/api/trpc',
        headers() {
          if (!authState.authenticated) {
            return {}
          }
          return {
            authorization: `Bearer:${authState.idToken}`,
          }
        },
        transformer: superjson,
      }),
    [authState]
  )
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  )
}
